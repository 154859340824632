import request from '@/utils/request'
// 获取资讯信息列表-居民端
export function WeGetRealInfoPage (query) {
  return request({
    url: 'RealInfo/WeGetRealInfoPage',
    method: 'get',
    params: query
  })
}
// 获取资讯信息-居民端
export function WeGetRealInfo (query) {
  return request({
    url: 'PartyInfo/WeGetRealInfo',
    method: 'get',
    params: query
  })
}
// 小程序-获取社区活动分页列表
export function WeGetActivityPage (query) {
  return request({
    url: 'Activity/WeGetActivityPage',
    method: 'get',
    params: query
  })
}
// 获取社区活动详情
export function WeGetActivityDetail (query) {
  return request({
    url: 'Activity/WeGetActivityDetail',
    method: 'get',
    params: query
  })
}
// 小程序-报名参与社区活动
export function WeEnrolActivity (data) {
  return request({
    url: 'Activity/WeEnrolActivity',
    method: 'post',
    data: data
  })
}
// 小程序获取商铺分页列表
export function WeGetShopPage (query) {
  return request({
    url: 'Shop/WeGetShopPage',
    method: 'get',
    params: query
  })
}
// 获取资讯信息列表-党员端
export function WeGetPartyInfoPage (query) {
  return request({
    url: 'PartyInfo/WeGetPartyInfoPage',
    method: 'get',
    params: query
  })
}
// 获取党员积分分页列表[党员端]
export function WeGetPMIntegralPage (query) {
  return request({
    url: 'Party/WeGetPMIntegralPage',
    method: 'get',
    params: query
  })
}
// 小程序-获取党组活动分页列表
export function WeGetMyActivityPage (query) {
  return request({
    url: 'PBActivity/WeGetMyActivityPage',
    method: 'get',
    params: query
  })
}
// 获取资讯信息-党员端
export function WeGetPartyInfo (query) {
  return request({
    url: 'PartyInfo/WeGetPartyInfo',
    method: 'get',
    params: query
  })
}
// 小程序党建大事件时间轴列表
export function WxGetDateAxisList (query) {
  return request({
    url: 'PartyInfo/WxGetDateAxisList',
    method: 'get',
    params: query
  })
}
// 居民端-获取我的商铺列表
export function WxGetMyShopList (query) {
  return request({
    url: 'Shop/WxGetMyShopList',
    method: 'get',
    params: query
  })
}

// 居民端-重新绑定微信
export function WxReBindShop (data) {
  return request({
    url: 'Shop/WxReBindShop',
    method: 'post',
    data
  })
}

// 居民端-获取我的企业信息列表
export function WxGetMyEnterpriseList (query) {
  return request({
    url: 'Enterprise/WxGetMyEnterpriseList',
    method: 'get',
    params: query
  })
}
// 居民端-获取单位从业人员列表
export function WxGetEntStaffPage (query) {
  return request({
    url: 'Staff/WxGetEntStaffPage',
    method: 'get',
    params: query
  })
}
// 居民端-保存从业人员信息
export function WxSaveStaff (data) {
  return request({
    url: 'Staff/WxSaveStaff',
    method: 'post',
    data
  })
}

// 获取从业人员详情
export function GetStaff (query) {
  return request({
    url: 'Staff/GetStaff',
    method: 'get',
    params: query
  })
}

// 删除员工信息
export function WxDeleteStaff (data) {
  return request({
    url: 'Staff/WxDeleteStaff',
    method: 'post',
    data
  })
}
// 居民端-获取我的隔离登记信息列表
export function WxGetIsolationPage (query) {
  return request({
    url: 'Isolation/WxGetIsolationPage',
    method: 'get',
    params: query
  })
}


// 居民端-我的返洛排查列表
export function WxGetReportingPage (query) {
  return request({
    url: 'Vaccine/WxGetReportingPage',
    method: 'get',
    params: query
  })
}

// 居民端-我的疫苗排查列表
export function WxGetCheckPage (query) {
  return request({
    url: 'Vaccine/WxGetCheckPage',
    method: 'get',
    params: query
  })
}

// 居民端-更新疫苗接种状态
export function WxUpdateVaccinateStatus (data) {
  return request({
    url: 'Vaccine/WxUpdateVaccinateStatus',
    method: 'post',
    data
  })
}

// 居民端-我的返洛排查列表
export function WxGetOutReportingPage (query) {
  return request({
    url: 'Vaccine/WxGetOutReportingPage',
    method: 'get',
    params: query
  })
}
// 居民端-社区活动分类
export function WeGetPBActivityPage (query) {
  return request({
    url: 'PBActivityKind/WeGetPBActivityPage',
    method: 'get',
    params: query
  })
}
// 小程序-获取社区活动分页列表
export function WePBGetActivityPage (query) {
  return request({
    url: 'PBActivity/WeGetActivityPage',
    method: 'get',
    params: query
  })
}

// 获取社区活动详情
export function WeGetPBActivityDetail (query) {
  return request({
    url: 'PBActivity/WeGetActivityDetail',
    method: 'get',
    params: query
  })
}

// 小程序-报名参与社区活动
export function WePBEnrolActivity (data) {
  return request({
    url: 'PBActivity/WeEnrolPBActivity',
    method: 'post',
    data: data
  })
}

// 小程序-获取社区资讯分类
export function WeGetInfoKindInfoPage (query) {
  return request({
    url: 'InfoKind/WeGetInfoKindInfoPage',
    method: 'get',
    params: query
  })
}

// 获取资讯信息列表-居民端
export function WePBGetRealInfoPage (query) {
  return request({
    url: 'PartyInfo/WeGetRealInfoPage',
    method: 'get',
    params: query
  })
}

// 发布活动记录【党建小程序使用】
export function WeRecActLogs (data) {
  return request({
    url: 'PBActivity/WeRecActLogs',
    method: 'post',
    data: data
  })
}

// 获取党组活动详情
export function WeGetActivityDetailPB (query) {
  return request({
    url: 'PBActivity/WeGetActivityDetail',
    method: 'get',
    params: query
  })
}
// 获取商品分页列表
export function WxGetMyGoodsPage (query) {
  return request({
    url: 'Goods/WxGetMyGoodsPage',
    method: 'get',
    params: query
  })
}
// 获取商品详情
export function WxGetMyGoods (query) {
  return request({
    url: 'Goods/WxGetMyGoods',
    method: 'get',
    params: query
  })
}
// 获取商品列表
export function WxGetGoodsPage (query) {
  return request({
    url: 'Goods/WxGetGoodsPage',
    method: 'get',
    params: query
  })
}

// 获取商品详情
export function WxGetGoods (query) {
  return request({
    url: 'Goods/WxGetGoods',
    method: 'get',
    params: query
  })
}

// 保存商品
export function WxMySaveGoodsSort (data) {
  return request({
    url: 'Goods/WxMySaveGoodsSort',
    method: 'post',
    data: data
  })
}
// 删除商品
export function WxMyDeleteGoods (data) {
  return request({
    url: 'Goods/WxMyDeleteGoods',
    method: 'post',
    data: data
  })
}

// 获取商品类别列表
export function WxGetGoodsCateList (query) {
  return request({
    url: 'GoodsCate/WxGetGoodsCateList',
    method: 'get',
    params: query
  })
}

// 获取商品分类列表
export function WxGetGoodsSortList (query) {
  return request({
    url: 'GoodsSort/WxGetGoodsSortList',
    method: 'get',
    params: query
  })
}

// 获取商品分类列表
export function WxGetMyGoodsSortList (query) {
  return request({
    url: 'GoodsSort/WxGetMyGoodsSortList',
    method: 'get',
    params: query
  })
}
// 保存商品分类
export function WxSaveGoodsSort (data) {
  return request({
    url: 'GoodsSort/WxSaveGoodsSort',
    method: 'post',
    data: data
  })
}

// 删除商品
export function WxDeleteGoodsSort (data) {
  return request({
    url: 'GoodsSort/WxDeleteGoodsSort',
    method: 'post',
    data: data
  })
}
// 获取商品分类详情
export function WxGetMyGoodsSort (query) {
  return request({
    url: 'GoodsSort/WxGetMyGoodsSort',
    method: 'get',
    params: query
  })
}

// 获取商品订单
export function WxGetShopOrderPage (query) {
  return request({
    url: 'ShopOrder/WxGetShopOrderPage',
    method: 'get',
    params: query
  })
}

// 接收订单，居民端商铺使用
export function WxRecviceOrder (data) {
  return request({
    url: 'ShopOrder/WxRecviceOrder',
    method: 'post',
    data: data
  })
}

// 接收订单，居民端商铺使用
export function WxFinishOrder (data) {
  return request({
    url: 'ShopOrder/WxFinishOrder',
    method: 'post',
    data: data
  })
}


export function GetActivityPage (query) {
  return request({
    url: 'PBActivity/GetActivityPage',
    method: 'get',
    params: query
  })
}
// 获取七牛云token
export function UploadQiniuUpToken (data) {
  return request({
    url: 'UploadFile/UploadQiniuUpToken',
    method: 'post',
    data
  })
}
//七牛云视频上传
export function UploadQiniuVideo (data) {
  return request({
    url: 'UploadFile/UploadQiniuVideo',
    method: 'post',
    data
  })
}
// 获取促销活动列表-商家
export function WxGetMyPromotionPage (query) {
  return request({
    url: 'Promotion/WxGetMyPromotionPage',
    method: 'get',
    params: query
  })
}
// 获取促销活动详情-商家
export function WxGetMyPromotion (query) {
  return request({
    url: 'Promotion/WxGetMyPromotion',
    method: 'get',
    params: query
  })
}
// 保存促销活动-商铺
export function WxSaveMyPromotion (data) {
  return request({
    url: 'Promotion/WxSaveMyPromotion',
    method: 'post',
    data
  })
}

// 删除促销活动
export function WxDeleteMyPromotion (data) {
  return request({
    url: 'Promotion/WxDeleteMyPromotion',
    method: 'post',
    data
  })
}

// 获取促销活动详情-居民
export function WxGetPromotion (query) {
  return request({
    url: 'Promotion/WxGetPromotion',
    method: 'get',
    params: query
  })
}

// 参加促销活动-居民
export function WxEnrolPromotion (data) {
  return request({
    url: 'Promotion/WxEnrolPromotion',
    method: 'post',
    data
  })
}

// 获取我的促销活动报名列表
export function WxGetActEnrolPage (query) {
  return request({
    url: 'Promotion/WxGetActEnrolPage',
    method: 'get',
    params: query
  })
}

// 获取促销活动报名列表
export function WxGetMyActEnrolPage (query) {
  return request({
    url: 'Promotion/WxGetMyActEnrolPage',
    method: 'get',
    params: query
  })
}

// 获取核酸检测报备信息列表
export function WxGetNATestPage (query) {
  return request({
    url: 'NATest/WxGetNATestPage',
    method: 'get',
    params: query
  })
}
// 获取会员信息
export function WeGetMemberByOpenID (query) {
  return request({
    url: 'Member/WeGetMemberByOpenID',
    method: 'get',
    params: query
  })
}
